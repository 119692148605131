import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faMale, faLaptop, faEnvelope} from '@fortawesome/free-solid-svg-icons'


const Navigation = () => (
    <Nav>
        <NavList>
            <NavListItem><Link to="/"><FontAwesomeIcon icon={faHome} />Top</Link></NavListItem>
            <NavListItem><Link to="/about"><FontAwesomeIcon icon={faMale} />About</Link></NavListItem>
            <NavListItem><Link to="/portfolio"><FontAwesomeIcon icon={faLaptop} />Work</Link></NavListItem>
            <NavListItem><Link to="/contact"><FontAwesomeIcon icon={faEnvelope} />Contact</Link></NavListItem>
        </NavList>
    </Nav>
)

export default Navigation

const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
    width: 100%;
    height: calc(50px + env(safe-area-inset-bottom));
`

const NavList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
        max-width: 600px;
    }
`

const NavListItem = styled.li`
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1px;

    & a {
        text-decoration: none;
        color: #000;
    }

    & svg {
        margin-right: 5px;
    }

    @media (max-width: 768px) {
        max-width: 600px;
    }
`