/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Navigation from "./navigation"
import "./layout.css"
import { createGlobalStyle } from 'styled-components';

const Layout = ({ children }) => {

  return (
    <>
      <GlobalStyle />
          <main>{children}</main>
          <Navigation />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Quicksand', sans-serif;
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    background-repeat: repeat-y;
    color: #000;
  }
  
  .pl-Content__Wrap {
    width: 90%;
    background: #FFF;
    border-radius: 5px;
    padding: 1rem;
    box-sizing: border-box;
    margin-bottom: 1em;
  }

  .pl-post {
    background: #FFF;
  }
`

export default Layout
